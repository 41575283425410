import DashboardLayout from 'examples/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'examples/Navbars/DashboardNavbar'

import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import homeImage from 'assets/images/img1.png'

import MDBox from 'components/MDBox'
import { Grid } from '@mui/material'
import MDTypography from 'components/MDTypography'
function Dashboard() {
  const navigate = useNavigate()

  useEffect(() => {
    if (!localStorage.getItem('userToken')) {
      navigate('/admin/sign-in')
    }
  }, [navigate])

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        <Grid container justifyContent="center" alignItems="center">
          <Grid item xs={12} md={12} lg={12}>
            {/* Avatar/Image */}
            <MDBox display="flex" justifyContent="center" mt={4} mb={4}>
              <img alt="Waving Woman" src={homeImage} />
            </MDBox>

            {/* Text */}
            <MDBox textAlign="center" mb={2}>
              <MDTypography variant="h1">Hi there! Examine the administrative panel</MDTypography>
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  )
}

export default Dashboard
