import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'
import MDButton from 'components/MDButton'
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'examples/Navbars/DashboardNavbar'

import MDInput from 'components/MDInput'
function NotificationAdd() {
  const token = localStorage.getItem('userToken')
  // const [users, setUsers] = useState([])
  const [message, setMessage] = useState([])
  // const [userOptions, setUserOptions] = useState([])
  const [error, setError] = useState(null)
  // const [loading, setLoading] = useState(true)
  const navigate = useNavigate()
  // const [options, setOptions] = useState([])
  // const [searchValue, setSearchValue] = useState('')
  const [address, setAddress] = useState('')
  const validateAddress = (value) => {
    const regex = /^0x[a-fA-F0-9]{40}$/

    // Split the input by commas and trim each address
    const addresses = value.split(',').map((addr) => addr.trim())

    // Check if any address is invalid
    if (addresses.some((address) => !regex.test(address))) {
      setError('Please enter valid wallet address(es).')
    } else {
      setError('')
    }
  }

  // const getUserList = useCallback(
  //   async (query) => {
  //     setLoading(true)
  //     try {
  //       const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}users/getAllUserList`, {
  //         method: 'GET',
  //         headers: {
  //           'Content-Type': 'application/json',
  //           Authorization: `Bearer ${token}`,
  //         },
  //       })
  //       if (!response.ok) {
  //         throw new Error('Failed to fetch data')
  //       }
  //       const jsonData = await response.json()

  //       const allUsers = jsonData.data.user.map((student) => ({
  //         title: student.address,
  //         id: student._id,
  //       }))
  //       // console.log(allUsers)
  //       setUserOptions(allUsers)
  //       setLoading(false)

  //       setOptions(allUsers)
  //     } catch (error) {
  //       console.error('Error fetching data:', error.message)
  //       //   setError('Error fetching data')
  //     }
  //   },
  //   [token],
  // )

  // useEffect(() => {
  //   getUserList()
  // }, [getUserList])

  const handleAddressChange = (e) => {
    const value = e.target.value
    setAddress(value)
    validateAddress(value)
  }

  // const handleInputChange = (_, value) => {
  //   setSearchValue(value)
  //   if (value.length >= 2) {
  //     getUserList(value) // Fetch data for input with 2+ characters
  //   } else {
  //     setOptions([]) // Clear options for empty or short input
  //   }
  // }

  const handleMessageChange = (e) => setMessage(e.target.value)

  const handleSubmit = async (e) => {
    e.preventDefault()
    if (!error && address) {
      console.log('Valid Ethereum address submitted:', address)
    } else {
      console.log('Please fix the errors.')
    }
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}users/send-notification`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          sender_type: 'Admin',
          notification_tag: 'multipleUsers',
          message: message,
          address: address,
        }),
      })

      const data = await response.json()

      if (!response.ok) {
        throw new Error(data.message || 'Failed to add new user')
      }
      navigate('/notifications')
    } catch (err) {
      setError(err.message)
    }
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox mx={2} mt={-3} py={3} px={2} variant="gradient" bgColor="info" borderRadius="lg" coloredShadow="info">
                <MDTypography variant="h6" color="white">
                  Send Notification
                </MDTypography>
              </MDBox>
              {/* {loading ? (
                <MDBox display="flex" justifyContent="center" alignItems="center" height="40vh">
                  <CircularProgress size={60} color="primary" />
                  <MDTypography variant="h6" ml={2}>
                    Loading your data...
                  </MDTypography>
                </MDBox>
              ) : ( */}
              <MDBox pt={4} pb={13} px={5}>
                <MDBox component="form" role="form" onSubmit={handleSubmit}>
                  {/* <MDBox mb={2}>
                    <Stack spacing={3}> */}
                  {/* <Autocomplete
                          multiple
                          id="tags-outlined"
                          options={userOptions}
                          getOptionLabel={(option) => option.title}
                          filterSelectedOptions
                          value={users}
                          onChange={handleUserChange}
                          renderInput={(params) => <TextField {...params} label="Select User" placeholder="Users" />}
                        /> */}

                  {/* <Autocomplete
                        multiple
                        id="tags-outlined"
                        options={options}
                        getOptionLabel={(option) => option.title}
                        filterSelectedOptions
                        onInputChange={handleInputChange}
                        loading={loading}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Select User"
                            placeholder="Users"
                            InputProps={{
                              ...params.InputProps,
                              endAdornment: (
                                <>
                                  {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                  {params.InputProps.endAdornment}
                                </>
                              ),
                            }}
                          />
                        )}
                      /> */}
                  {/* </Stack>
                  </MDBox> */}

                  <MDBox mb={2}>
                    <MDInput
                      type="text"
                      id="address"
                      value={address}
                      // onChange={handleChange}
                      placeholder="Enter wallet addresses"
                      label="Wallet Address"
                      variant="standard"
                      fullWidth
                      onChange={handleAddressChange}
                      required
                    />

                    {error && <p style={{ color: 'red' }}>{error}</p>}
                  </MDBox>
                  <MDBox mb={2}>
                    <MDInput
                      type="text"
                      label="Message"
                      variant="standard"
                      fullWidth
                      value={message}
                      onChange={handleMessageChange}
                      required
                      helperText={!message && 'Message is required'}
                    />
                  </MDBox>
                  {/* {error && (
                    <MDBox mt={2}>
                      <MDTypography color="error">{error}</MDTypography>
                    </MDBox>
                  )} */}
                  <MDBox mt={4} mb={1}>
                    <MDButton variant="gradient" color="info" type="submit" disabled={!!error}>
                      Send
                    </MDButton>{' '}
                    <MDButton variant="gradient" color="secondary" type="button" onClick={() => navigate('/notifications')}>
                      Cancel
                    </MDButton>
                  </MDBox>
                </MDBox>
              </MDBox>
              {/* )} */}
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  )
}

export default NotificationAdd
