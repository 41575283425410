import { useMemo, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useTable, usePagination, useGlobalFilter, useSortBy } from 'react-table'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableContainer from '@mui/material/TableContainer'
import TableRow from '@mui/material/TableRow'
import Icon from '@mui/material/Icon'
import Autocomplete from '@mui/material/Autocomplete'
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'
import MDInput from 'components/MDInput'
import MDPagination from 'components/MDPagination'
import DataTableHeadCell from 'examples/Tables/DataTable/DataTableHeadCell'
import DataTableBodyCell from 'examples/Tables/DataTable/DataTableBodyCell'

// import 'react-datepicker/dist/react-datepicker.css'
// import { Box } from '@mui/material'
function DataTable({ entriesPerPage, canSearch, showTotalEntries, table, pagination, isSorted, noEndBorder }) {
  const defaultValue = entriesPerPage.defaultValue ? entriesPerPage.defaultValue : 10
  const entries = entriesPerPage.entries ? entriesPerPage.entries.map((el) => el.toString()) : ['5', '10', '15', '20', '25']
  const columns = useMemo(() => table.columns, [table])
  const data = useMemo(() => table.rows, [table])

  const tableInstance = useTable({ columns, data, initialState: { pageIndex: 0 } }, useGlobalFilter, useSortBy, usePagination)

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    page,
    pageOptions,
    canPreviousPage,
    canNextPage,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    // setGlobalFilter,
    state: { pageIndex, pageSize },
  } = tableInstance

  useEffect(() => setPageSize(defaultValue || 10), [defaultValue, setPageSize])

  const setEntriesPerPage = (value) => setPageSize(value)

  const renderPagination = pageOptions.map((option) => (
    <MDPagination item key={option} onClick={() => gotoPage(Number(option))} active={pageIndex === option}>
      {option + 1}
    </MDPagination>
  ))

  const handleInputPagination = ({ target: { value } }) => (value > pageOptions.length || value < 0 ? gotoPage(0) : gotoPage(Number(value)))

  const customizedPageOptions = pageOptions.map((option) => option + 1)

  const handleInputPaginationValue = ({ target: value }) => gotoPage(Number(value.value - 1))

  // const [search, setSearch] = useState(globalFilter)

  // const onSearchChange = useAsyncDebounce((value) => {
  //   setGlobalFilter(value || undefined)
  // }, 100)

  // A function that sets the sorted value for the table
  const setSortedValue = (column) => {
    let sortedValue

    if (isSorted && column.isSorted) {
      sortedValue = column.isSortedDesc ? 'desc' : 'asce'
    } else if (isSorted) {
      sortedValue = 'none'
    } else {
      sortedValue = false
    }

    return sortedValue
  }

  // Setting the entries starting point
  // const entriesStart = pageIndex === 0 ? pageIndex + 1 : pageIndex * pageSize + 1

  // Setting the entries ending point
  // let entriesEnd

  // if (pageIndex === 0) {
  //   entriesEnd = pageSize
  // } else if (pageIndex === pageOptions.length - 1) {
  //   entriesEnd = rows.length
  // } else {
  //   entriesEnd = pageSize * (pageIndex + 1)
  // }
  const entriesStart = pageIndex * pageSize + 1

  // Setting the entries ending point
  const entriesEnd = Math.min((pageIndex + 1) * pageSize, 10)
  // const [startDate, setStartDate] = useState(null)
  // const [endDate, setEndDate] = useState(null)
  // const [isOpen, setIsOpen] = useState(false)

  // const handleClick = () => {
  //   setIsOpen(!isOpen)
  // }

  // const handleChange = (dates) => {
  //   const [start, end] = dates
  //   setStartDate(start)
  //   setEndDate(end)
  // }
  return (
    <TableContainer sx={{ boxShadow: 'none' }}>
      {entriesPerPage || canSearch ? (
        <MDBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
          {entriesPerPage && (
            <MDBox display="flex" alignItems="center">
              <Autocomplete
                disableClearable
                value={pageSize.toString()}
                options={entries}
                onChange={(event, newValue) => {
                  setEntriesPerPage(parseInt(newValue, 10))
                }}
                size="small"
                sx={{ width: '5rem' }}
                renderInput={(params) => <MDInput {...params} />}
              />
              <MDTypography variant="caption" color="secondary">
                &nbsp;&nbsp;Entries per page
              </MDTypography>
            </MDBox>
          )}
          {/* {canSearch && (
            <MDBox display="flex" alignItems="center" width="16rem" ml="auto">
              <MDInput
                placeholder="Search by wallet address"
                value={search}
                size="small"
                fullWidth
                onChange={({ currentTarget }) => {
                  setSearch(search)
                  onSearchChange(currentTarget.value)
                }}
                InputProps={{
                  sx: {
                    borderRadius: '9.434rem !important', 
                  },
                }}
              />
              
            </MDBox>
          )} */}
        </MDBox>
      ) : null}
      <Table {...getTableProps()}>
        <MDBox component="thead">
          {headerGroups.map((headerGroup, key) => (
            <TableRow key={key} {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column, idx) => (
                <DataTableHeadCell
                  key={idx}
                  {...column.getHeaderProps(isSorted && column.getSortByToggleProps())}
                  width={column.width ? column.width : 'auto'}
                  align={column.align ? column.align : 'left'}
                  sorted={setSortedValue(column)}
                >
                  {column.render('Header')}
                </DataTableHeadCell>
              ))}
            </TableRow>
          ))}
        </MDBox>
        <TableBody {...getTableBodyProps()}>
          {page.map((row, key) => {
            prepareRow(row)
            return (
              <TableRow key={key} {...row.getRowProps()}>
                {row.cells.map((cell, idx) => (
                  <DataTableBodyCell key={idx} noBorder={noEndBorder && rows.length - 1 === key} align={cell.column.align ? cell.column.align : 'left'} {...cell.getCellProps()}>
                    {cell.render('Cell')}
                  </DataTableBodyCell>
                ))}
              </TableRow>
            )
          })}
        </TableBody>
      </Table>

      <MDBox
        display="flex"
        flexDirection={{ xs: 'column', sm: 'row' }}
        justifyContent="space-between"
        alignItems={{ xs: 'flex-start', sm: 'center' }}
        p={!showTotalEntries && pageOptions.length === 1 ? 0 : 3}
      >
        {showTotalEntries && (
          <MDBox mb={{ xs: 3, sm: 0 }}>
            <MDTypography variant="button" color="secondary" fontWeight="regular">
              Showing {entriesStart} to {entriesEnd} of 10 entries
            </MDTypography>
          </MDBox>
        )}
        {pageOptions.length > 1 && (
          <MDPagination variant={pagination.variant ? pagination.variant : 'gradient'} color={pagination.color ? pagination.color : 'info'}>
            {canPreviousPage && (
              <MDPagination item onClick={() => previousPage()}>
                <Icon sx={{ fontWeight: 'bold' }}>chevron_left</Icon>
              </MDPagination>
            )}
            {renderPagination.length > 6 ? (
              <MDBox width="5rem" mx={1}>
                <MDInput
                  inputProps={{ type: 'number', min: 1, max: customizedPageOptions.length }}
                  value={customizedPageOptions[pageIndex]}
                  onChange={(handleInputPagination, handleInputPaginationValue)}
                />
              </MDBox>
            ) : (
              renderPagination
            )}
            {canNextPage && (
              <MDPagination item onClick={() => nextPage()}>
                <Icon sx={{ fontWeight: 'bold' }}>chevron_right</Icon>
              </MDPagination>
            )}
          </MDPagination>
        )}
      </MDBox>
    </TableContainer>
  )
}

// Setting default values for the props of DataTable
DataTable.defaultProps = {
  entriesPerPage: { defaultValue: 10, entries: [5, 10, 15, 20, 25] },
  canSearch: false,
  showTotalEntries: true,
  pagination: { variant: 'gradient', color: 'info' },
  isSorted: true,
  noEndBorder: false,
}

// Typechecking props for the DataTable
DataTable.propTypes = {
  entriesPerPage: PropTypes.oneOfType([
    PropTypes.shape({
      defaultValue: PropTypes.number,
      entries: PropTypes.arrayOf(PropTypes.number),
    }),
    PropTypes.bool,
  ]),
  canSearch: PropTypes.bool,
  showTotalEntries: PropTypes.bool,
  table: PropTypes.shape({
    columns: PropTypes.array.isRequired,
    rows: PropTypes.array.isRequired,
    totalRows: PropTypes.oneOfType([PropTypes.number, PropTypes.array]), // Allow number or array
  }).isRequired,
  pagination: PropTypes.shape({
    variant: PropTypes.oneOf(['contained', 'gradient']),
    color: PropTypes.oneOf(['primary', 'secondary', 'info', 'success', 'warning', 'error', 'dark', 'light']),
  }),
  isSorted: PropTypes.bool,
  noEndBorder: PropTypes.bool,
}

export default DataTable
