import { useEffect, useState } from 'react'
import { useLocation, NavLink } from 'react-router-dom'
import PropTypes from 'prop-types'
import List from '@mui/material/List'
import Divider from '@mui/material/Divider'
import Link from '@mui/material/Link'
import Icon from '@mui/material/Icon'
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'
import MDButton from 'components/MDButton'

import SidenavCollapse from 'examples/Sidenav/SidenavCollapse'
import SidenavRoot from 'examples/Sidenav/SidenavRoot'
import sidenavLogoLabel from 'examples/Sidenav/styles/sidenav'
import { useMaterialUIController, setMiniSidenav, setTransparentSidenav, setWhiteSidenav } from 'context'
import { useNavigate } from 'react-router-dom'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import Paper from '@mui/material/Paper'
import Draggable from 'react-draggable'

// var loaded = false

function PaperComponent(props) {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  )
}

function Sidenav({ color, brand, brandName, routes, ...rest }) {
  const [controller, dispatch] = useMaterialUIController()
  const { miniSidenav, transparentSidenav, whiteSidenav, darkMode, sidenavColor } = controller
  const location = useLocation()
  const collapseName = location.pathname.replace('/', '')
  const navigate = useNavigate()
  let textColor = 'white'

  if (transparentSidenav || (whiteSidenav && !darkMode)) {
    textColor = 'dark'
  } else if (whiteSidenav && darkMode) {
    textColor = 'inherit'
  }

  const closeSidenav = () => setMiniSidenav(dispatch, true)

  useEffect(() => {
    function handleMiniSidenav() {
      setMiniSidenav(dispatch, window.innerWidth < 1200)
      setTransparentSidenav(dispatch, window.innerWidth < 1200 ? false : transparentSidenav)
      setWhiteSidenav(dispatch, window.innerWidth < 1200 ? false : whiteSidenav)
    }
    window.addEventListener('resize', handleMiniSidenav)
    handleMiniSidenav()

    return () => window.removeEventListener('resize', handleMiniSidenav)
  }, [dispatch, location, transparentSidenav, whiteSidenav])

  const onClickHandleLogout = async () => {
    localStorage.removeItem('userToken')

    navigate('/admin/sign-in')
  }

  const [userData, setUserData] = useState(null)
  const userToken = localStorage.getItem('userToken')

  useEffect(() => {
    const fetchUserData = async () => {
      // if (loaded) return
      try {
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}auth/get-subadmin-profile`, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        })
        const results = await response.json()
        // loaded = true
        setUserData(results.data)
      } catch (error) {
        const errorMessage = error.response?.data?.message || 'Upload failed. Please try again.'
        console.log(errorMessage)
      }
    }

    if (!userToken) {
      navigate('/')
    } else {
      fetchUserData()
    }
  }, [userToken, navigate])

  // Render all the routes from the routes.js (All the visible items on the Sidenav)
  // console.log(userData)
  const renderRoutes =
    userData && userData.role === 'Subadmin'
      ? routes.map(({ type, name, icon, items, title, noCollapse, key, href, route, service_id }) => {
          let returnValue

          const hasServiceId1 = userData && Array.isArray(userData.services) && userData.services.some((service) => service.service_id === service_id)

          if (hasServiceId1) {
            if (type === 'collapse') {
              returnValue = href ? (
                <Link href={href} key={key} target="_blank" rel="noreferrer" sx={{ textDecoration: 'none' }}>
                  <SidenavCollapse name={name} icon={icon} active={key === collapseName} noCollapse={noCollapse} items={items} userData={userData} />
                </Link>
              ) : (
                <NavLink key={key} to={route}>
                  <SidenavCollapse name={name} icon={icon} active={key === collapseName} items={items} userData={userData} />
                </NavLink>
              )
            } else if (type === 'title') {
              returnValue = (
                <MDTypography key={key} color={textColor} display="block" variant="caption" fontWeight="bold" textTransform="uppercase" pl={3} mt={2} mb={1} ml={1}>
                  {title}
                </MDTypography>
              )
            } else if (type === 'divider') {
              returnValue = <Divider key={key} light={(!darkMode && !whiteSidenav && !transparentSidenav) || (darkMode && !transparentSidenav && whiteSidenav)} />
            }
          }

          return returnValue
        })
      : routes.map(({ type, name, icon, items, title, noCollapse, key, href, route, service_id }) => {
          let returnValue

          if (type === 'collapse') {
            returnValue = href ? (
              <Link href={href} key={key} target="_blank" rel="noreferrer" sx={{ textDecoration: 'none' }}>
                <SidenavCollapse name={name} icon={icon} active={key === collapseName} noCollapse={noCollapse} items={items} />
              </Link>
            ) : (
              <NavLink key={key} to={route}>
                <SidenavCollapse name={name} icon={icon} active={key === collapseName} items={items} />
              </NavLink>
            )
          } else if (type === 'title') {
            returnValue = (
              <MDTypography key={key} color={textColor} display="block" variant="caption" fontWeight="bold" textTransform="uppercase" pl={3} mt={2} mb={1} ml={1}>
                {title}
              </MDTypography>
            )
          } else if (type === 'divider') {
            returnValue = <Divider key={key} light={(!darkMode && !whiteSidenav && !transparentSidenav) || (darkMode && !transparentSidenav && whiteSidenav)} />
          }

          return returnValue
        })

  const [open, setOpen] = useState(false)

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <SidenavRoot {...rest} variant="permanent" ownerState={{ transparentSidenav, whiteSidenav, miniSidenav, darkMode }}>
      <MDBox pt={3} pb={1} px={4} textAlign="center">
        <MDBox display={{ xs: 'block', xl: 'none' }} position="absolute" top={0} right={0} p={1.625} onClick={closeSidenav} sx={{ cursor: 'pointer' }}>
          <MDTypography variant="h6" color="secondary">
            <Icon sx={{ fontWeight: 'bold' }}>close</Icon>
          </MDTypography>
        </MDBox>
        <MDBox component={NavLink} to="/dashboard" display="flex" alignItems="center">
          {brand && <MDBox component="img" src={brand} alt="Brand" width="10rem" />}
          <MDBox width={!brandName && '100%'} sx={(theme) => sidenavLogoLabel(theme, { miniSidenav })}>
            <MDTypography component="h6" variant="button" fontWeight="medium" color={textColor}>
              {brandName}
            </MDTypography>
          </MDBox>
        </MDBox>
      </MDBox>
      <Divider light={(!darkMode && !whiteSidenav && !transparentSidenav) || (darkMode && !transparentSidenav && whiteSidenav)} />
      <List>{renderRoutes}</List>
      <MDBox p={2} mt="auto">
        <MDButton onClick={handleClickOpen} variant="gradient" color={sidenavColor} fullWidth>
          Logout
        </MDButton>
        <Dialog open={open} onClose={handleClose} PaperComponent={PaperComponent} aria-labelledby="draggable-dialog-title">
          <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
            Are you leaving?
          </DialogTitle>
          <DialogContent>
            <DialogContentText>Are you sure want to log out? All your unsaved data will be lost.</DialogContentText>
          </DialogContent>
          <DialogActions>
            <MDButton onClick={handleClose} color="secondary">
              No
            </MDButton>
            <MDButton onClick={onClickHandleLogout} color="info">
              Yes
            </MDButton>
          </DialogActions>
        </Dialog>
      </MDBox>
    </SidenavRoot>
  )
}

// Setting default values for the props of Sidenav
Sidenav.defaultProps = {
  color: 'info',
  brand: '',
}

// Typechecking props for the Sidenav
Sidenav.propTypes = {
  color: PropTypes.oneOf(['primary', 'secondary', 'info', 'success', 'warning', 'error', 'dark']),
  brand: PropTypes.string,
  brandName: PropTypes.string.isRequired,
  routes: PropTypes.arrayOf(PropTypes.object).isRequired,
}

export default Sidenav
