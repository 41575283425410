import React, { useState, useEffect, useCallback } from 'react'
import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'
import MDButton from 'components/MDButton'
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'examples/Navbars/DashboardNavbar'
import DataTable from 'examples/Tables/DataTable'
import { useNavigate } from 'react-router-dom'
import toast, { Toaster } from 'react-hot-toast'
import Icon from '@mui/material/Icon'

import { RotatingLines } from 'react-loader-spinner'
function Coupon() {
  const navigate = useNavigate()
  const token = localStorage.getItem('userToken')
  const [page, setPage] = useState(1)
  const [loading, setLoading] = useState(false)
  const handleAddButtonClick = () => {
    navigate('/coupon/add')
  }

  const [data, setData] = useState({
    columns: [
      {
        Header: 'Sr.No',
        accessor: 'srno',
        align: 'left',
      },
      {
        Header: 'Wallet Address',
        accessor: 'address',
        align: 'left',
      },

      {
        Header: 'Created At',
        accessor: 'created_at',
        align: 'left',
      },
    ],
    rows: [],
  })

  const formatDate = (isoDateString) => {
    const date = new Date(isoDateString)
    const options = {
      day: 'numeric',
      month: 'long',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
    }
    return date.toLocaleDateString('en-US', options)
  }

  const fetchData = useCallback(
    async (page) => {
      setLoading(true)
      try {
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}users/get-user-coupons/${page}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        })

        if (!response.ok) {
          toast.error('The coupon list is not found.')
        }

        const jsonData = await response.json()
        setLoading(false)
        const rows = jsonData.data.userData.map((user, index) => ({
          srno: (page - 1) * 10 + index + 1,
          address: user.address ? user.address : '-',

          // users:
          //   user.userDetails.length > 0 ? (
          //     <MDBox
          //       sx={{
          //         maxHeight: 150,
          //         overflowY: 'auto',
          //       }}
          //     >
          //       {user.userDetails.map((item) => (
          //         <List key={item._id}>
          //           {' '}

          //           <ListItem style={{ color: 'rgb(5 110 17)' }}>
          //             <ListItemIcon style={{ color: ' rgb(5 110 17)', width: 1 }}>
          //               <Icon fontSize="medium">person</Icon>
          //             </ListItemIcon>

          //             <ListItemText primary={item.address || item.name || 'Unknown'} />
          //           </ListItem>
          //         </List>
          //       ))}
          //     </MDBox>
          //   ) : (
          //     <MDBox textAlign="center"> - </MDBox>
          //   ),
          created_at: (
            <MDBox lineHeight={1} textAlign="left">
              <MDTypography display="block" variant="caption" color="text" fontWeight="medium">
                {formatDate(user.created_at)}
              </MDTypography>
            </MDBox>
          ),
        }))

        const totalRows = jsonData.data.totalUsersCount

        setData((prevData) => ({
          ...prevData,
          rows: rows,
          totalRows: totalRows,
        }))
      } catch (error) {
        console.log('Error fetching data:', error.message)
      }
    },
    [token],
  )

  useEffect(() => {
    fetchData(page)
  }, [page, fetchData])

  function handlePrePage() {
    setPage((prevPage) => Math.max(prevPage - 1, 1))
    // handleDebouncedFetchData(page - 1)
  }

  function handleNextPage() {
    setPage((prevPage) => Math.min(prevPage + 1, totalPages))
    // handleDebouncedFetchData(page + 1)
  }
  const [rowsPerPage] = useState(10)
  const totalPagesRowCount = data?.totalRows || 0
  const totalPages = Math.ceil(totalPagesRowCount / rowsPerPage)

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Toaster />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <MDTypography variant="h6" color="white">
                  Coupons (100%)
                </MDTypography>
                <MDButton variant="gradient" color="dark" size="large" onClick={handleAddButtonClick}>
                  Add Coupons
                </MDButton>
              </MDBox>
              <MDBox pt={3}>
                {loading ? (
                  <MDTypography align="center">
                    <RotatingLines strokeColor="grey" strokeWidth="5" animationDuration="0.75" width="96" visible={true} />
                  </MDTypography>
                ) : (
                  <DataTable table={data} isSorted={false} entriesPerPage={false} showTotalEntries={false} canSearch={true} noEndBorder />
                )}

                <MDBox display="flex" justifyContent="center" alignItems="center" mt={10} mb={2} px={3} gap={2}>
                  {/* Previous Button */}
                  <MDButton
                    variant="contained"
                    color="info"
                    size="medium"
                    startIcon={<Icon>chevron_left</Icon>}
                    onClick={handlePrePage}
                    disabled={page === 1} // Disable on the first page
                    sx={{ minWidth: 120 }}
                  >
                    Previous
                  </MDButton>

                  {/* Current Page Display */}
                  <MDTypography variant="h6" color="text">
                    Page {page} of {totalPages}
                  </MDTypography>

                  {/* Next Button */}
                  <MDButton
                    variant="contained"
                    color="info"
                    size="medium"
                    endIcon={<Icon>chevron_right</Icon>}
                    onClick={handleNextPage}
                    disabled={totalPages === 0 || page === totalPages} // Disable on the last page
                    sx={{ minWidth: 120 }}
                  >
                    Next
                  </MDButton>
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  )
}

export default Coupon
