import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'
import MDButton from 'components/MDButton'
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'examples/Navbars/DashboardNavbar'

import MDInput from 'components/MDInput'
function CouponAdd() {
  const token = localStorage.getItem('userToken')
  // const [users, setUsers] = useState([])
  const [address, setAddress] = useState('')
  // const [userOptions, setUserOptions] = useState([])
  const [error, setError] = useState(null)
  // const [loading, setLoading] = useState(true)
  const navigate = useNavigate()

  const validateAddress = (value) => {
    const regex = /^0x[a-fA-F0-9]{40}$/

    // Split the input by commas and trim each address
    const addresses = value.split(',').map((addr) => addr.trim())

    // Check if any address is invalid
    if (addresses.some((address) => !regex.test(address))) {
      setError('Please enter valid wallet address(es).')
    } else {
      setError('')
    }
  }

  // const getUserList = useCallback(async () => {
  //   // setLoading(true)
  //   try {
  //     const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}users/getAllUserList`, {
  //       method: 'GET',
  //       headers: {
  //         'Content-Type': 'application/json',
  //         Authorization: `Bearer ${token}`,
  //       },
  //     })
  //     if (!response.ok) {
  //       throw new Error('Failed to fetch data')
  //     }
  //     const jsonData = await response.json()

  //     const allUsers = jsonData.data.user.map((student) => ({
  //       title: student.address,
  //       id: student._id,
  //     }))

  //     // setUserOptions(allUsers)
  //     // setLoading(false)
  //   } catch (error) {
  //     console.error('Error fetching data:', error.message)
  //   }
  // }, [token])

  // useEffect(() => {
  //   getUserList()
  // }, [getUserList])

  // const handleUserChange = (event, newValue) => {
  //   setUsers(newValue)
  // }

  // const handleAddressChange = (e) => setAddress(e.target.value)

  const handleAddressChange = (e) => {
    const value = e.target.value
    setAddress(value)
    validateAddress(value)
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    if (!error && address) {
      console.log('Valid Ethereum address submitted:', address)
    } else {
      console.log('Please fix the errors.')
    }
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}users/add-coupon`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          address: address,
          // user_ids: users.map((user) => user.id),
        }),
      })

      const data = await response.json()

      if (!response.ok) {
        throw new Error(data.message || 'Failed to add new user')
      }
      navigate('/coupons')
    } catch (err) {
      setError(err.message)
    }
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox mx={2} mt={-3} py={3} px={2} variant="gradient" bgColor="info" borderRadius="lg" coloredShadow="info">
                <MDTypography variant="h6" color="white">
                  Add Coupon
                </MDTypography>
              </MDBox>
              {/* {loading ? (
                <MDBox display="flex" justifyContent="center" alignItems="center" height="40vh">
                  <CircularProgress size={60} color="primary" />
                  <MDTypography variant="h6" ml={2}>
                    Loading your data...
                  </MDTypography>
                </MDBox>
              ) : ( */}
              <MDBox pt={4} pb={13} px={5}>
                <MDBox component="form" role="form" onSubmit={handleSubmit}>
                  {/* <MDBox mb={2}>
                      <Stack spacing={3}>
                        <Autocomplete
                          multiple
                          id="tags-outlined"
                          options={userOptions}
                          getOptionLabel={(option) => option.title}
                          filterSelectedOptions
                          value={users}
                          onChange={handleUserChange}
                          renderInput={(params) => <TextField {...params} label="Select User" placeholder="Users" />}
                        />
                      </Stack>
                    </MDBox> */}
                  <MDBox mb={2}>
                    <MDInput
                      type="text"
                      id="address"
                      value={address}
                      // onChange={handleChange}
                      placeholder="Enter wallet addresses"
                      label="Wallet Address"
                      variant="standard"
                      fullWidth
                      onChange={handleAddressChange}
                      required
                    />

                    {error && <p style={{ color: 'red' }}>{error}</p>}
                  </MDBox>
                  <MDBox mb={2}>
                    <MDInput type="text" label="100% Voucher" variant="standard" fullWidth disabled />
                  </MDBox>

                  <MDBox mt={4} mb={1}>
                    <MDButton variant="gradient" color="info" type="submit" disabled={!!error}>
                      Save
                    </MDButton>{' '}
                    <MDButton variant="gradient" color="secondary" type="button" onClick={() => navigate('/coupons')}>
                      Cancel
                    </MDButton>
                  </MDBox>
                </MDBox>
              </MDBox>
              {/* )} */}
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  )
}

export default CouponAdd
