import Grid from '@mui/material/Grid'
import Divider from '@mui/material/Divider'
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'examples/Navbars/DashboardNavbar'
import Footer from 'examples/Footer'
import ProfileInfoCard from 'examples/Cards/InfoCards/ProfileInfoCard'

import { useParams, useNavigate } from 'react-router-dom'
import { useEffect, useState } from 'react'

import Card from '@mui/material/Card'
import MDButton from 'components/MDButton'
import Icon from '@mui/material/Icon'
import MDAvatar from 'components/MDAvatar'
import burceMars from 'assets/images/bruce-mars.jpg'
import backgroundImage from 'assets/images/bg-profile.jpeg'
function SubadminView() {
  const { id } = useParams()
  const token = localStorage.getItem('userToken')
  const [userData, setUserData] = useState(null)
  const navigate = useNavigate()

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}auth/get-subadmin-byID/${id}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        })
        const data = await response.json()
        if (response.ok) {
          setUserData(data.data)
        } else {
          throw new Error(data.message || 'Failed to fetch student data')
        }
      } catch (err) {
        console.log(err.message)
      }
    }
    fetchUserData()
  }, [id, token])

  const formatDate = (isoDateString) => {
    const date = new Date(isoDateString)
    const options = {
      day: 'numeric',
      month: 'long',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
    }
    return date.toLocaleDateString('en-US', options)
  }
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mb={2} />
      <MDButton variant="gradient" color="secondary" type="button" onClick={() => navigate('/subadmin')}>
        <Icon>arrow_back</Icon> Back
      </MDButton>
      <MDBox position="relative" mb={5} mt={1}>
        <MDBox
          display="flex"
          alignItems="center"
          position="relative"
          minHeight="18.75rem"
          borderRadius="xl"
          sx={{
            backgroundImage: ({ functions: { rgba, linearGradient }, palette: { gradients } }) =>
              `${linearGradient(rgba(gradients.info.main, 0.6), rgba(gradients.info.state, 0.6))}, url(${backgroundImage})`,
            backgroundSize: 'cover',
            backgroundPosition: '50%',
            overflow: 'hidden',
          }}
        />
        <Card
          sx={{
            position: 'relative',
            mt: -8,
            mx: 3,
            py: 2,
            px: 2,
          }}
        >
          <Grid container spacing={3} alignItems="center">
            <Grid item>
              <MDAvatar src={burceMars} alt="profile-image" size="xl" shadow="sm" />
            </Grid>
            <Grid item>
              <MDBox height="100%" mt={0.5} lineHeight={1}>
                <MDTypography variant="h5" fontWeight="medium">
                  {userData ? userData.name : ''}
                </MDTypography>
                <MDTypography variant="button" color="text" fontWeight="regular">
                  {userData ? userData.email : ''}
                </MDTypography>
              </MDBox>
            </Grid>
          </Grid>
        </Card>

        <MDBox mt={5} mb={3} px={4}>
          <Grid container spacing={1}>
            <Grid item xs={12} md={12} xl={12} sx={{ display: 'flex' }}>
              <Divider orientation="vertical" sx={{ ml: -2, mr: 1 }} />
              {userData ? (
                <>
                  {' '}
                  <ProfileInfoCard
                    title="profile information"
                    description="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.  "
                    info={{
                      fullName: userData.name,

                      email: userData.email,
                      // location: "UAE",
                    }}
                    social={[]}
                    action={{
                      route: `/subadmin/edit/${userData._id}`,
                      tooltip: 'Edit Profile',
                    }}
                    shadow={false}
                  />
                </>
              ) : (
                <p>Loading...</p>
              )}
              <Divider orientation="vertical" sx={{ mx: 0 }} />
            </Grid>
            <Grid item xs={12} md={12} xl={12} sx={{ display: 'flex' }}>
              <Divider orientation="vertical" sx={{ ml: -2, mr: 1 }} />
              {userData ? (
                <Card sx={{ height: '100%', boxShadow: 'none' }}>
                  <Grid item xs={12} xl={12}>
                    {/* <ProfilesList title=" Total assigned user for verification" profiles={profilesListData} shadow={false} /> */}

                    <Card sx={{ height: '100%', boxShadow: 'none' }}>
                      <MDBox pt={2} px={2} display="flex" justifyContent="space-between" alignItems="center">
                        <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
                          Total assigned user for verification
                        </MDTypography>
                        <MDTypography
                          variant="h6"
                          fontWeight="medium"
                          textTransform="capitalize"
                          color="white"
                          ml={2}
                          sx={{ backgroundColor: 'green', padding: '8px 16px', borderRadius: '50%' }}
                        >
                          {userData.userCount}
                        </MDTypography>
                      </MDBox>

                      <MDBox p={2}>
                        <MDBox component="ul" display="flex" flexDirection="column" p={0} m={0}>
                          {userData && userData.userData
                            ? userData.userData.map((listItem, index) => (
                                <MDBox key={index} component="li" display="flex" alignItems="center" justifyContent="space-between" py={1} mb={1}>
                                  <MDBox display="flex" alignItems="center">
                                    <MDBox mr={2}>{index + 1}.</MDBox>
                                    <MDBox display="flex" flexDirection="column" alignItems="flex-start">
                                      <MDTypography variant="button" fontWeight="medium">
                                        {listItem.address}
                                      </MDTypography>
                                      <MDTypography variant="caption" color="text">
                                        {formatDate(listItem.updatedAt)}
                                      </MDTypography>
                                    </MDBox>
                                  </MDBox>
                                  <MDBox ml={10}>
                                    <MDTypography variant="button" fontWeight="medium" align="right" color="info">
                                      {' '}
                                      {listItem.status
                                        ? listItem.status === 'Under_review'
                                          ? 'Under Review'
                                          : listItem.status === 'Processing'
                                          ? 'Create NFT'
                                          : listItem.status
                                        : '-'}
                                    </MDTypography>
                                  </MDBox>
                                </MDBox>
                              ))
                            : 'No user assigned'}
                        </MDBox>
                      </MDBox>
                    </Card>
                  </Grid>
                </Card>
              ) : (
                <p>Loading...</p>
              )}
              <Divider orientation="vertical" sx={{ mx: 0 }} />
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>

      <Footer />
    </DashboardLayout>
  )
}

export default SubadminView
